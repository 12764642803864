import { createContext, useContext } from 'react';

interface Props {
  setAuthToken: Function;
  authToken: string;
}

export const AuthContext = createContext<Props>({
  authToken: '',
  setAuthToken: () => {},
});

export function useAuth() {
  return useContext(AuthContext);
}
